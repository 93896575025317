var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _BufferReader_instances, _BufferReader_byte_backward;
export class BufferReader {
    constructor(buffer) {
        _BufferReader_instances.add(this);
        this._buffer = buffer;
        this.position = 0;
    }
    byte() {
        return this._buffer[this.position++];
    }
    int(bytes) {
        let value = 0;
        this.skip(bytes);
        for (let i = 0; i < bytes; i++) {
            value <<= 8;
            value += __classPrivateFieldGet(this, _BufferReader_instances, "m", _BufferReader_byte_backward).call(this);
        }
        this.skip(bytes);
        return value;
    }
    chars(bytes) {
        let value = "";
        for (let i = 0; i < bytes; i++) {
            value = value + String.fromCharCode(this.byte());
        }
        return value;
    }
    skip(bytes) {
        this.position += bytes;
    }
    fourcc() {
        return this.chars(4);
    }
    dword() {
        return this.int(4);
    }
    word() {
        return this.int(2);
    }
    long() {
        return this.int(4);
    }
    obj(...types) {
        var _a;
        const result = {};
        for (let i = 0; i < types.length; i++) {
            let singleResult = this[types[i].t](...((_a = types[i].a) !== null && _a !== void 0 ? _a : []));
            if (typeof types[i].k !== "undefined")
                result[types[i].k] = singleResult;
        }
        return result;
    }
    ended() {
        return this.position >= this._buffer.length;
    }
}
_BufferReader_instances = new WeakSet(), _BufferReader_byte_backward = function _BufferReader_byte_backward() {
    return this._buffer[--this.position];
};
