var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _ListChunk_skipJunk;
import { BufferReader } from "./bufferReader";
export class Chunk {
    constructor(buffer, skipJunk = false) {
        this.buffer = buffer;
        this._reader = new BufferReader(this.buffer);
        this.chunkId = this._reader.fourcc();
        this.size = this._reader.dword();
        this.data = buffer.subarray(8);
    }
    describe(n = 0) {
        return `${" ".repeat(n)}'${this.chunkId}'(${this.size})\n`;
    }
}
export class ListChunk extends Chunk {
    readNext() {
        if (!this._reader)
            return true;
        if (this._reader.ended()) {
            this.listReader.onEnd();
            this.listRead = true;
            delete this._reader;
            return true;
        }
        let nextChunkType = this._reader.fourcc();
        let nextChunkSize = this._reader.dword();
        if (!__classPrivateFieldGet(this, _ListChunk_skipJunk, "f") || nextChunkType !== "JUNK") {
            let newChunk = new (getConstructorForId(nextChunkType))(this.buffer.subarray(this._reader.position - 8, this._reader.position + nextChunkSize), __classPrivateFieldGet(this, _ListChunk_skipJunk, "f"));
            this.listReader.onChunk(newChunk);
        }
        this._reader.skip(nextChunkSize + (nextChunkSize % 2));
        return false;
    }
    constructor(buffer, skipJunk = false) {
        super(buffer, skipJunk);
        _ListChunk_skipJunk.set(this, void 0);
        __classPrivateFieldSet(this, _ListChunk_skipJunk, skipJunk, "f");
        this.listType = this._reader.fourcc();
        this.listRead = false;
        this.listReader = new (getListParserForId(this.listType))();
        this.listReader.onStart();
        while (!this.readNext())
            ;
    }
    describe(n = 0) {
        let val = `${" ".repeat(n)}${this.chunkId} ('${this.listType}'\n`;
        return val + this.listReader.describe(n);
    }
    findChunkByFilter(filter) {
        if (!this.listReader.subChunks)
            return null;
        for (let i = 0; i < this.listReader.subChunks.length; i++) {
            const chunk = this.listReader.subChunks[i];
            if (filter(chunk)) {
                return chunk;
            }
            if (chunk instanceof ListChunk) {
                const res = chunk.findChunkByFilter(filter);
                if (res)
                    return res;
            }
        }
        return null;
    }
}
_ListChunk_skipJunk = new WeakMap();
export class DefaultListReader {
    onStart() {
        this.subChunks = [];
    }
    onChunk(chunk) {
        this.subChunks.push(chunk);
    }
    onEnd() { }
    describe(n = 0) {
        let val = "";
        this.subChunks.forEach((subChunk) => {
            val += subChunk.describe(n + 1);
        });
        val += `${" ".repeat(n)})\n`;
        return val;
    }
}
const listParsers = {};
const constructors = {
    LIST: ListChunk,
    RIFF: ListChunk,
};
function getConstructorForId(chunkId) {
    var _a;
    return (_a = constructors[chunkId]) !== null && _a !== void 0 ? _a : Chunk;
}
function registerChunk(chunkId, chunkConstructor) {
    constructors[chunkId] = chunkConstructor;
}
function unregisterChunk(chunkId) {
    return delete constructors[chunkId];
}
function getListParserForId(listType) {
    var _a;
    return (_a = listParsers[listType]) !== null && _a !== void 0 ? _a : DefaultListReader;
}
function registerListParser(listType, listParser) {
    listParsers[listType] = listParser;
}
function unregisterListParser(listType) {
    return delete listParsers[listType];
}
export const chunkRegistration = {
    current: constructors,
    register: registerChunk,
    unregister: unregisterChunk,
};
export const listRegistration = {
    current: listParsers,
    register: registerListParser,
    unregister: unregisterListParser,
};
