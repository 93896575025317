var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _hdrlListReader_state, _hdrlListReader_streams, _strlListReader_state;
import { Chunk } from "./riff";
import { DefaultListReader, ListChunk } from "./riff";
function checkEnded(reader, chunk) {
    if (!reader.ended())
        throw new Error(`Data after expected end of chunk in ${chunk} (read ${reader.position} of ${reader._buffer.length})`);
}
export class avihChunk extends Chunk {
    constructor(buffer, skipJunk = false) {
        super(buffer, skipJunk);
        this.microSecPerFrame = this._reader.dword();
        this.maxBytesPerSec = this._reader.dword();
        this.paddingGranularity = this._reader.dword();
        this.flags = this._reader.dword();
        this.totalFrames = this._reader.dword();
        this.initialFrames = this._reader.dword();
        this.streams = this._reader.dword();
        this.suggestedBufferSize = this._reader.dword();
        this.width = this._reader.dword();
        this.height = this._reader.dword();
        this._reader.skip(4 * 4);
        checkEnded(this._reader, "avihChunk");
        delete this._reader;
    }
    describe(n = 0) {
        return `${" ".repeat(n)}'${this.chunkId}'(${this.microSecPerFrame} us/f, ${this.maxBytesPerSec} max B/s, ${this.paddingGranularity} padding, 0x${this.flags.toString(16)}, ${this.totalFrames} total frames, ${this.initialFrames} initial frames, ${this.streams} streams, ${this.suggestedBufferSize} suggested buffer size, ${this.width}x${this.height})\n`;
    }
}
export class strhChunk extends Chunk {
    constructor(buffer, skipJunk = false) {
        super(buffer, skipJunk);
        this.type = this._reader.fourcc();
        this.handler = this._reader.fourcc();
        this.flags = this._reader.dword();
        this.priority = this._reader.word();
        this.language = this._reader.word();
        this.initialFrames = this._reader.dword();
        this.scale = this._reader.dword();
        this.rate = this._reader.dword();
        this.start = this._reader.dword();
        this.length = this._reader.dword();
        this.suggestedBufferSize = this._reader.dword();
        this.quality = this._reader.dword();
        this.sampleSize = this._reader.dword();
        this.frame = this._reader.obj({ t: "word", k: "left" }, { t: "word", k: "top" }, { t: "word", k: "right" }, { t: "word", k: "bottom" });
        checkEnded(this._reader, "strhChunk");
        delete this._reader;
    }
    describe(n = 0) {
        return `${" ".repeat(n)}'${this.chunkId}'(${this.type} (${this.handler}), 0x${this.flags.toString(16)}, ${this.priority}, ${this.language}, ${this.initialFrames} initial frames, ${this.rate}/${this.scale} samples per second, ${this.start} start, ${this.length}, ${this.suggestedBufferSize} suggested buffer size, ${this.quality} quality, ${this.sampleSize} sample size, ${JSON.stringify(this.frame)})\n`;
    }
}
export class strfBITMAPINFOChunk extends Chunk {
    constructor(buffer) {
        super(buffer, false);
        this.biSize = this._reader.dword();
        this.width = this._reader.long();
        this.height = this._reader.long();
        this.planes = this._reader.word();
        this.bitCount = this._reader.word();
        this.compression = this._reader.obj({ k: "numeric", t: "dword" }, { t: "skip", a: [-4] }, { k: "fourcc", t: "fourcc" });
        this.sizeImage = this._reader.dword();
        this.xPelsPerMeter = this._reader.long();
        this.yPelsPerMeter = this._reader.long();
        this.clrUsed = this._reader.dword();
        this.clrImportant = this._reader.dword();
        checkEnded(this._reader, "strfBITMAPINFOChunk");
        delete this._reader;
    }
}
export class hdrlListReader extends DefaultListReader {
    constructor() {
        super(...arguments);
        _hdrlListReader_state.set(this, void 0);
        _hdrlListReader_streams.set(this, void 0);
    }
    onStart() {
        __classPrivateFieldSet(this, _hdrlListReader_state, 0, "f");
    }
    onChunk(chunk) {
        var _a, _b;
        switch (__classPrivateFieldGet(this, _hdrlListReader_state, "f")) {
            case 0:
                if (chunk instanceof avihChunk) {
                    this.mainHeader = chunk;
                    __classPrivateFieldSet(this, _hdrlListReader_streams, this.mainHeader.streams, "f");
                    this.streams = [];
                    __classPrivateFieldSet(this, _hdrlListReader_state, (_a = __classPrivateFieldGet(this, _hdrlListReader_state, "f"), _a++, _a), "f");
                }
                return;
            case 1:
                if (chunk instanceof ListChunk &&
                    chunk.listReader instanceof strlListReader) {
                    this.streams.push(chunk.listReader);
                    __classPrivateFieldSet(this, _hdrlListReader_streams, (_b = __classPrivateFieldGet(this, _hdrlListReader_streams, "f"), _b--, _b), "f");
                }
                return;
        }
    }
    onEnd() {
        if (__classPrivateFieldGet(this, _hdrlListReader_streams, "f"))
            console.warn("Stream count does not match with actual count: " + __classPrivateFieldGet(this, _hdrlListReader_streams, "f"));
    }
    describeStreams(n = 0) {
        let result = "";
        this.streams.forEach((v) => {
            result += v.describe(n + 1) + "\n";
        });
        return result;
    }
    describe(n = 0) {
        return `${this.mainHeader.describe(n + 1)}\n${" ".repeat(n + 1)}${this.streams.length} stream(s):\n${this.describeStreams(n)}`;
    }
}
_hdrlListReader_state = new WeakMap(), _hdrlListReader_streams = new WeakMap();
export class strlListReader extends DefaultListReader {
    constructor() {
        super(...arguments);
        _strlListReader_state.set(this, void 0);
    }
    onStart() {
        __classPrivateFieldSet(this, _strlListReader_state, 0, "f");
    }
    /**
     *
     * @param {Chunk} chunk
     */
    onChunk(chunk) {
        var _a, _b;
        switch (__classPrivateFieldGet(this, _strlListReader_state, "f")) {
            case 0:
                if (chunk.chunkId === "strh" && chunk instanceof strhChunk) {
                    this.header = chunk;
                    __classPrivateFieldSet(this, _strlListReader_state, (_a = __classPrivateFieldGet(this, _strlListReader_state, "f"), _a++, _a), "f");
                }
                return;
            case 1: {
                if (chunk.chunkId === "strf") {
                    if (this.header.type === "vids") {
                        this.format = new strfBITMAPINFOChunk(chunk.buffer);
                    }
                    else {
                        this.format = chunk;
                    }
                    __classPrivateFieldSet(this, _strlListReader_state, (_b = __classPrivateFieldGet(this, _strlListReader_state, "f"), _b++, _b), "f");
                }
                return;
            }
        }
    }
    onEnd() {
        if (__classPrivateFieldGet(this, _strlListReader_state, "f") !== 2)
            console.warn("strlListParser finished without strh and strf");
    }
    describe(n = 0) {
        return `${this.header.describe(n + 1)}\n${this.format ? this.format.describe(n + 1) : ""}`;
    }
}
_strlListReader_state = new WeakMap();
class moviListReader extends DefaultListReader {
    onStart() {
        this.streams = {};
    }
    onChunk(chunk) {
        if (chunk.chunkId === "LIST" && chunk instanceof ListChunk) {
            if (chunk.listType === "rec ") {
                chunk.listReader.subChunks.forEach(this.onChunk.bind(this));
            }
            return;
        }
        const streamId = +chunk.chunkId.slice(0, 2);
        const streamType = chunk.chunkId.slice(2);
        if (!this.streams[streamId])
            this.streams[streamId] = [];
        this.streams[streamId].push({
            data: chunk.buffer.subarray(8),
            type: streamType,
        });
    }
    onEnd() { }
    describe(n = 0) {
        return ``;
    }
}
export function registerAllChunks(registration) {
    registration.register("avih", avihChunk);
    registration.register("strh", strhChunk);
}
export function registerAllListParsers(registration) {
    registration.register("strl", strlListReader);
    registration.register("hdrl", hdrlListReader);
    registration.register("movi", moviListReader);
}
export function extractImportantFromParsed(riff) {
    const important = {};
    important.hdrl = riff.findChunkByFilter((v) => v.listReader instanceof hdrlListReader).listReader;
    important.movi = riff.findChunkByFilter((v) => v.listReader instanceof moviListReader).listReader;
    return important;
}
