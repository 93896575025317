var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _VideoPlayer_currentDescriptor, _VideoPlayer_videoStreams, _VideoPlayer_canvas, _VideoPlayer_lastTimestamp, _VideoPlayer_playbackTime, _VideoPlayer_completedTime, _VideoPlayer_statusCb, _VideoPlayer_lastStatus;
import { registerAllChunks, registerAllListParsers, extractImportantFromParsed, } from "./avi";
import { initControls } from "./controls";
import { ListChunk, chunkRegistration, listRegistration } from "./riff";
registerAllChunks(chunkRegistration);
registerAllListParsers(listRegistration);
class VideoPlayer {
    constructor(canvas) {
        _VideoPlayer_currentDescriptor.set(this, void 0);
        _VideoPlayer_videoStreams.set(this, void 0);
        _VideoPlayer_canvas.set(this, void 0);
        _VideoPlayer_lastTimestamp.set(this, void 0);
        _VideoPlayer_playbackTime.set(this, void 0);
        _VideoPlayer_completedTime.set(this, void 0);
        _VideoPlayer_statusCb.set(this, void 0);
        _VideoPlayer_lastStatus.set(this, void 0);
        __classPrivateFieldSet(this, _VideoPlayer_canvas, canvas, "f");
        this.doPlayback = this.doPlayback.bind(this);
        __classPrivateFieldSet(this, _VideoPlayer_statusCb, () => { }, "f");
        __classPrivateFieldSet(this, _VideoPlayer_lastStatus, {
            completedTime: 0,
            currentTime: 0,
            looping: false,
            playing: false,
        }, "f");
    }
    loadFile(aviDescriptor) {
        __classPrivateFieldSet(this, _VideoPlayer_currentDescriptor, aviDescriptor, "f");
        this.doStop = true;
        __classPrivateFieldSet(this, _VideoPlayer_lastTimestamp, undefined, "f");
        __classPrivateFieldSet(this, _VideoPlayer_videoStreams, aviDescriptor.hdrl.streams
            .map((v, i) => ({ header: v, i, stream: aviDescriptor.movi.streams[i] }))
            .filter((v) => {
            return v.header.header.type === "vids";
        }), "f");
        __classPrivateFieldSet(this, _VideoPlayer_completedTime, (__classPrivateFieldGet(this, _VideoPlayer_currentDescriptor, "f").hdrl.mainHeader.totalFrames *
            __classPrivateFieldGet(this, _VideoPlayer_currentDescriptor, "f").hdrl.mainHeader.microSecPerFrame) /
            1000, "f");
        this.seek(0);
    }
    displayFrameByTime(currentTimeMs) {
        const frameNum = Math.floor(currentTimeMs /
            (__classPrivateFieldGet(this, _VideoPlayer_currentDescriptor, "f").hdrl.mainHeader.microSecPerFrame / 1000));
        return this.displayFrameByNum(frameNum);
    }
    displayFrameByNum(frameNumber) {
        if (!__classPrivateFieldGet(this, _VideoPlayer_videoStreams, "f")[0].stream[frameNumber])
            return false;
        const data = __classPrivateFieldGet(this, _VideoPlayer_videoStreams, "f")[0].stream[frameNumber].data;
        const blob = new Blob([data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        URL.revokeObjectURL(__classPrivateFieldGet(this, _VideoPlayer_canvas, "f").src);
        __classPrivateFieldGet(this, _VideoPlayer_canvas, "f").src = url;
        return true;
    }
    doPlayback(timestamp) {
        if (this.doStop) {
            __classPrivateFieldSet(this, _VideoPlayer_lastTimestamp, undefined, "f");
            this.sendStatus({
                completedTime: __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"),
                currentTime: __classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f"),
                playing: false,
                looping: this.loop,
            });
            return;
        }
        if (__classPrivateFieldGet(this, _VideoPlayer_lastTimestamp, "f")) {
            this.sendStatus({
                completedTime: __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"),
                currentTime: __classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f"),
                playing: true,
                looping: this.loop,
            });
            const delta = timestamp - __classPrivateFieldGet(this, _VideoPlayer_lastTimestamp, "f"); // in ms
            __classPrivateFieldSet(this, _VideoPlayer_playbackTime, __classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f") + delta, "f");
            if (!this.displayFrameByTime(__classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f"))) {
                __classPrivateFieldSet(this, _VideoPlayer_playbackTime, 0, "f"); // do not use seek -> image stays on last frame, but play starts from start
                if (!this.loop) {
                    this.doStop = true;
                    __classPrivateFieldSet(this, _VideoPlayer_lastTimestamp, undefined, "f");
                    this.sendStatus({
                        completedTime: __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"),
                        currentTime: __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"),
                        playing: false,
                        looping: this.loop,
                    });
                    return;
                }
            }
        }
        __classPrivateFieldSet(this, _VideoPlayer_lastTimestamp, timestamp, "f");
        requestAnimationFrame(this.doPlayback);
    }
    startPlayback() {
        if (__classPrivateFieldGet(this, _VideoPlayer_lastTimestamp, "f") === undefined) {
            this.doStop = false;
            this.doPlayback(undefined);
        }
    }
    stopPlayback() {
        this.doStop = true;
    }
    seek(position) {
        __classPrivateFieldSet(this, _VideoPlayer_playbackTime, position, "f");
        if (__classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f") < 0)
            __classPrivateFieldSet(this, _VideoPlayer_playbackTime, 0, "f");
        if (__classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f") > __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"))
            __classPrivateFieldSet(this, _VideoPlayer_playbackTime, __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"), "f");
        if (this.doStop) {
            this.displayFrameByTime(__classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f"));
            this.sendStatus({
                completedTime: __classPrivateFieldGet(this, _VideoPlayer_completedTime, "f"),
                currentTime: __classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f"),
                playing: false,
                looping: this.loop,
            });
        }
    }
    stepFrames(n) {
        if (this.doStop) {
            this.seek(__classPrivateFieldGet(this, _VideoPlayer_playbackTime, "f") + n * (__classPrivateFieldGet(this, _VideoPlayer_currentDescriptor, "f").hdrl.mainHeader.microSecPerFrame / 1000));
        }
    }
    pause() {
        this.stopPlayback();
    }
    play() {
        this.startPlayback();
    }
    sendStatus(status) {
        __classPrivateFieldSet(this, _VideoPlayer_lastStatus, status, "f");
        this.resendStatus();
    }
    updateLoopFlag() {
        if (this.doStop) {
            __classPrivateFieldGet(this, _VideoPlayer_lastStatus, "f").looping = this.loop;
            this.resendStatus();
        }
    }
    resendStatus() {
        __classPrivateFieldGet(this, _VideoPlayer_statusCb, "f").call(this, __classPrivateFieldGet(this, _VideoPlayer_lastStatus, "f"));
    }
    setStatusCallback(cb) {
        __classPrivateFieldSet(this, _VideoPlayer_statusCb, cb, "f");
    }
}
_VideoPlayer_currentDescriptor = new WeakMap(), _VideoPlayer_videoStreams = new WeakMap(), _VideoPlayer_canvas = new WeakMap(), _VideoPlayer_lastTimestamp = new WeakMap(), _VideoPlayer_playbackTime = new WeakMap(), _VideoPlayer_completedTime = new WeakMap(), _VideoPlayer_statusCb = new WeakMap(), _VideoPlayer_lastStatus = new WeakMap();
function initMain() {
    let canvas;
    let player;
    let controls;
    window.addEventListener("DOMContentLoaded", () => {
        canvas = document.querySelector("img#contentFrame");
        player = new VideoPlayer(canvas);
        window.player = player;
        addEventListener("message", (e) => {
            if (e.data.type === "command") {
                switch (e.data.data) {
                    case "pause":
                        player.pause();
                        break;
                    case "play":
                        player.play();
                        break;
                    case "unloop":
                        player.loop = false;
                        player.updateLoopFlag();
                        break;
                    case "loop":
                        player.loop = true;
                        player.updateLoopFlag();
                        break;
                    case "stepBack":
                        player.stepFrames(-1);
                        break;
                    case "stepForwards":
                        player.stepFrames(1);
                        break;
                }
            }
            else if (e.data.type === "statusUpdate") {
                player.resendStatus();
            }
            else if (e.data.type === "transferFile") {
                let mainChunk = new ListChunk(e.data.data, true);
                player.loadFile(extractImportantFromParsed(mainChunk));
            }
        });
        player.setStatusCallback((e) => {
            if (controls) {
                controls.postMessage({ type: "status", data: e }, "*");
            }
        });
        function openControls() {
            document.querySelector("span#controlsInfo").style.display = "none";
            controls = window.open("?controls", "controls", "popup width=400 height=100");
        }
        window.addEventListener("dblclick", openControls);
    });
}
if (location.search === "?controls") {
    initControls();
}
else {
    initMain();
}
